
import { ref, reactive } from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
  components: {
    InputText,
    Button
  },
  props: {
    name: String,
    email: String,
    phone: String,
    credits: String,
    tokens: String,
    isBalanceEdit: Boolean
  },
  setup(props) {
    const isShow = ref(false);

    const profile = reactive({
      name: props.name,
      email: props.email,
      phone: props.phone
    });
    const balance = reactive({
      credits: props.credits,
      tokens: props.tokens
    });
    const password = reactive({
      old_password: '',
      new_password: '',
      replay_new_password: ''
    });

    return {
      isShow,
      password,
      profile,
      balance
    };
  }
};


import { ref } from 'vue';
import Toolbar from 'primevue/toolbar';

import { Avatar, Icon } from '../ui';

export default {
  components: {
    Toolbar,
    Avatar,
    Icon
  },
  props: {
    name: String,
    credits: Number,
    tokens: Number,
    avatar: String
  },
  emits: ['newAvatar'],
  setup(props, { emit }) {
    const avatarPreview = ref(props.avatar);

    function setAvatar(event) {
      const file = event.target.files[0];
      emit('newAvatar', file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        avatarPreview.value = this.result;
      };
    }
    return {
      setAvatar,
      avatarPreview
    };
  }
};

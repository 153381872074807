import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center" }
const _hoisted_2 = { class: "ml-4" }
const _hoisted_3 = {
  key: 0,
  class: "_heading-2 mb-2"
}
const _hoisted_4 = { class: "flex" }
const _hoisted_5 = { class: "info flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Toolbar = _resolveComponent("Toolbar")!

  return (_openBlock(), _createBlock(_component_Toolbar, {
    class: _normalizeClass([_ctx.$style.core, "mb-5"])
  }, {
    left: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Avatar, {
          size: "big",
          img: $setup.avatarPreview || $props.avatar
        }, null, 8, ["img"]),
        _createElementVNode("div", _hoisted_2, [
          ($props.name)
            ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString($props.name), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", {
              class: _normalizeClass([_ctx.$style.upload, "flex align-items-center"])
            }, [
              _createElementVNode("input", {
                type: "file",
                ref: "avatar",
                onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.setAvatar && $setup.setAvatar(...args))),
                class: _normalizeClass(_ctx.$style.uploadInput)
              }, null, 34),
              _createVNode(_component_Icon, {
                className: "_img-base mr-2",
                name: "camera",
                size: 16
              }),
              _createElementVNode("span", {
                class: _normalizeClass(_ctx.$style.uploadLabel)
              }, _toDisplayString(_ctx.$t('profile.upload_photo')), 3)
            ], 2)
          ])
        ])
      ])
    ]),
    right: _withCtx(() => [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.$style.box, "text-center"])
        }, [
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.$style.label, "_text-3"])
          }, _toDisplayString(_ctx.$t('profile.available_tokens')), 3),
          _createElementVNode("p", {
            class: _normalizeClass([_ctx.$style.value, "_text-2"])
          }, _toDisplayString($props.tokens), 3)
        ], 2)
      ])
    ]),
    _: 1
  }, 8, ["class"]))
}

import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Toolbar from 'primevue/toolbar';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import { Icon, Empty, Loader, StatusBool } from '../../../components/ui';
import { Paginator } from '../../../components/common';
import { useStores } from '@/store/Stores';

export default {
  components: {
    Toolbar,
    InputText,
    Icon,
    DataTable,
    Column,
    Loader,
    Empty,
    StatusBool,
    Paginator
  },
  setup() {
    const { companyUsersStore, companyUsersStoreState } = useStores();

    const route = useRoute();
    const router = useRouter();
    const search = ref();

    companyUsersStore.init({ per_page: 15, page: route.query.page || 1, search: search.value });

    watch(route, () => {
      companyUsersStore.init({ per_page: 15, page: route.query.page || 1, search: search.value });
    });

    watch(search, (newValue) => {
      // const query = {...route.query, ...{ search: newValue }}
      router.push({ name: route.name as any, query: { page: 1, search: newValue } });
    });

    return {
      search,
      state: companyUsersStoreState
    };
  }
};

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ml-3 mr-3" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Toolbar = _resolveComponent("Toolbar")!

  return (_openBlock(), _createBlock(_component_Toolbar, { class: "mt-4" }, {
    left: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('paginator.info', { count: $props.count, total: $props.total })), 1)
    ]),
    right: _withCtx(() => [
      _createElementVNode("button", {
        class: _normalizeClass([[$setup.page !== 1 ? _ctx.$style.action : _ctx.$style.disabled], "mr-1"]),
        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.page += -1))
      }, [
        _createVNode(_component_Icon, { name: "chevron_left" })
      ], 2),
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(_ctx.$style.input),
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.page) = $event))
      }, null, 2), [
        [
          _vModelText,
          $setup.page,
          void 0,
          { lazy: true }
        ]
      ]),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('paginator.separator')), 1),
      _createElementVNode("input", {
        type: "text",
        class: _normalizeClass(_ctx.$style.input),
        disabled: "",
        value: $props.lastPage
      }, null, 10, _hoisted_2),
      _createElementVNode("button", {
        class: _normalizeClass([[$setup.page !== $props.lastPage ? _ctx.$style.action : _ctx.$style.disabled], "ml-1"]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.page += 1))
      }, [
        _createVNode(_component_Icon, { name: "chevron_right" })
      ], 2)
    ]),
    _: 1
  }))
}
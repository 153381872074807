
import { ref, watch } from 'vue';
import Toolbar from 'primevue/toolbar';
import { useRoute, useRouter } from 'vue-router';

import { Icon } from '../../components/ui';

export default {
  components: {
    Toolbar,
    Icon
  },
  props: {
    currentPage: Number,
    total: Number,
    lastPage: Number,
    perPage: Number,
    count: Number
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const page = ref(parseInt(props.currentPage));
    function name(newValue) {
      const regx = /[^0-9]/g;
      if (regx.test(newValue)) {
        newValue = newValue.replace(regx, '');
      }
      if (newValue < 1) {
        newValue = 1;
        page.value = 1;
        return;
      }
      if (newValue > props.lastPage) {
        newValue = props.lastPage;
        page.value = props.lastPage;
        return;
      }
      const query = { ...route.query, ...{ page: newValue || 1 } };
      page.value = newValue;
      router.push({ name: route.name as any, query: query });
    }
    watch(page, (newValue) => {
      if (props.currentPage !== newValue) {
        name(newValue);
      }
    });
    watch(
      () => props.currentPage,
      (newValue) => {
        name(parseInt(newValue));
      }
    );
    return {
      page
    };
  }
};
